<template>
    <div id="appCapsule">
        <div class="section">
            <div class="splash-page mt-5 mb-5">
                <h1>404</h1>
                <h2 class="mb-2">Page not found!</h2>
                <p>
                    You are not meant to be here.
                </p>
            </div>
        </div>

        <div class="fixed-bar">
            <div class="row">
                <div class="col-12">
                    <router-link :to="{name: 'MetricsWallet'}" class="btn btn-lg btn-outline-secondary btn-block goBack">
                        Back Home
                    </router-link>
<!--                    <a href="app-404.html#" class="btn btn-lg btn-outline-secondary btn-block goBack">Back Home</a>-->
                </div>
<!--                <div class="col-6">-->
<!--                    <a href="app-pages.html" class="btn btn-lg btn-primary btn-block">Try Again</a>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '404',
};
</script>

<style scoped>

</style>